.export-card {
  color: var(--hfont) !important;
  background-color: white;
  padding: 18px;

  .user-label {
    margin-top: 16px;
  }
  input {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--border1);
    margin-top: 4px;
    outline: none;
    color: var(--hfont) !important;
  }
}
.saveAs-card {
  @extend .export-card;
  padding: 0px 18px;
  background-color: transparent;
  input {
    width: auto;
    margin-top: 0px;
    padding: 8px;
  }
  // textArea {
  //   @extend input;
  // }
}

.add-export-select {
  .react-select__indicators {
    align-self: center;
  }
  .react-select__control {
    min-height: 50px;
    border: 1px solid !important;
    border-color: var(--border1) !important;
  }
}
