.nav,
.navtabs {
  border-bottom: 1px solid;
  border-color: var(--border1) !important;

  .nav-link {
    color: var(--hfont);
    font-size: 16px;

    font-weight: bold;
    &.active,
    &:hover {
      background-color: transparent;
      color: var(--mcolor);
      position: relative;
      border-bottom: 3px solid;
      border-color: var(--mcolor);
    }
  }
}

.triangle-up {
  position: relative;
}

.triangle-up::before {
  content: '';
  position: absolute;
  bottom: 0px; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #6754e2; /* Replace with your desired color */
}
