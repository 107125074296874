.select-label {
  width: 230px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  color: var(--hfont);
}
.label-viewer {
  background-color: white;
  border-radius: 4px;
  width: 100%;
}
.custom-col {
  width: 150px !important;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
.custom-content {
  input {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--border1);
    margin-top: 4px;
    outline: none;
    color: var(--hfont) !important;
  }
}
.custom-button {
  .action-button {
    border: none;
    background-color: var(--mcolor);
    border-radius: 5px;
    padding: 6px;
    justify-content: center;
    display: flex;
    color: white;
    margin: 2px;
    cursor: pointer;
    min-width: 90px;
    max-width: 100px;
    .action-text {
      margin-left: 5px;
    }
  }

  .action-button-outline {
    border: 1px solid var(--mcolor);
    border-radius: 5px;
    padding: 6px;
    justify-content: center;
    display: flex;
    color: var(--mcolor);
    margin: 2px;
    cursor: pointer;
    min-width: 80px;
    max-width: 130px;
    .action-text {
      margin-left: 5px;
    }
  }
}

.compare-with-select {
  .compare-with-selected-value {
    color: var(--hfont) !important;
    font-size: 1rem !important;
  }
}

.comapare-with-radio {
  .radio-hr {
    border: 1px solid #4f4f4f9c !important;
    margin: 0 -12px;
  }
  .comapare-with-radio-option {
    color: #4f4f4f !important;

    input[type="radio"]:checked {
      border-color: var(--mcolor);
    }

    input[type="radio"]:checked:after {
      border-color: var(--mcolor);
      background-color: var(--mcolor);
    }
  }
}

.custom_div_position {
  @media (max-width: 767px) {
    position: absolute;
    top: 10px;
    right: 12px;
  }
}

.filter_label {
  color: #485166;
  font-size: 18px;
  font-weight: 500;
}
