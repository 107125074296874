.main-card {
  background-color: white;
  border-radius: 20px;
  .currentRole {
    h4 {
      color: var(--mcolor);
    }
  }
  .techStack {
    h4 {
      color: var(--scolor);
    }
  }
}
