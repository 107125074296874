.add-permission-card {
  color: var(--hfont) !important;
  background-color: white;
  border-radius: 20px;
  padding: 36px;
  margin-top: 24px;

  .user-label {
    margin-top: 16px;
  }
  input {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--border1);
    margin-top: 4px;
    outline: none;
    color: var(--hfont) !important;
  }

  .user-card-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 24px;
    div {
      width: 10rem;
    }
  }
}
