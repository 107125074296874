.heading-content {
  h4 {
    background-color: var(--mcolor) !important;
    padding: 10px;

    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    color: white;
  }
  .child-content {
    padding: 20px;
  }
  .form-check .form-check-input:checked {
    border-color: #6754e2 !important;
    background-color: #6754e2 !important;
  }
  .form-check {
    margin-top: 15px;
  }
}
.text-input-box {
  width: 100%;
  div {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--border1);
    margin-top: 4px;
    outline: none;
    color: var(--hfont) !important;
  }
}
.profile-avatar {
  border-radius: 50%;
}
.notification-profile {
  color: #009efb;
  background-color: rgba(0, 158, 251, 0.15);
  border-radius: 5px;
  padding: 4px 14px 4px 14px;
}
.notification-report {
  color: #f62e52;
  background-color: rgba(246, 46, 82, 0.15);
  border-radius: 5px;
  padding: 4px 14px 4px 14px;
}
.date-text {
  font-size: 14px;
  color: var(--bfont);
}

.profile-container {
  .profile-image-placeholder {
    height: 150px;
    width: 150px;
    color: var(--mcolor);
    font-size: 80px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.image-name-overlfow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
