.modal-content {
  border-radius: 20px;
}
.modal-header {
  margin: 24px 24px 0px 24px;
}

// .btn-close {
//   background-color: #263b5e !important;
//   border-radius: 50% !important;
//   color: white !important;
//   font-weight: 900 !important;
//   opacity: 0.5;
//     background: transparent url('../../assets//svg/close-modal.svg') center/2em
//       auto no-repeat;
// }

::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
  background-color: transparent; /* Hide the scrollbar background */
}

::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Customize the color of the scrollbar thumb */
}
