.loader-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Univers Condensed !important;
  background-color: rgba(189, 189, 189, 0.4);
  position: fixed;
  z-index: 100;
}

.metrics-loader {
  color: #6754e2 !important;
}
