.select-category-block {
  height: 100%;
  .sidebar-height {
    // height: calc(100% - 60px);
    max-height: 100%;
    overflow-y: scroll;
  }
  .minimize-label {
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: var(--mcolor);
    }
  }
  .MuiFormControlLabel-root {
    margin: 0;
  }
  h3,
  .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: 600;
    font-family: "Univers EW", sans-serif;
    color: var(--hfont);

    @media only screen and (min-width: 1600px) {
      font-size: 18px;
    }
  }
}
.select-category {
  text-align: left;

  &:not(:first-child) {
    margin-top: 30px;

    @media only screen and (max-width: 1199px) {
      margin-top: 30px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 20px;

    @media only screen and (max-width: 1199px) {
      margin-bottom: 20px;
    }
  }

  .company-search {
    .comp-search-dropdown {
      max-height: 200px;
      overflow-y: auto;
    }
  }
  .category-block {
    span {
      line-height: initial;
      cursor: pointer;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Univers EW", sans-serif;
    color: var(--hfont);

    @media only screen and (min-width: 1600px) {
      font-size: 18px;
    }
  }

  // .checkbox-list {
  //   .list-group {
  //     border: unset;

  //     .list-group-item {
  //       padding: 0;
  //       background-color: transparent;
  //       border: unset;

  //       .form-check-label {
  //         font-family: inherit !important;
  //         font-size: 14px;
  //         line-height: 20px;
  //         color: var(--bfont);

  //         @media only screen and (min-width: 1600px) {
  //           font-size: 20px;
  //         }
  //       }
  //     }
  //   }

  //   .accordion-button:not(.collapsed)::after {
  //     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  //   }

  //   .accordion-button:not(.collapsed) {
  //     color: #fff;
  //     background-color: var(--mcolor);
  //   }
  //   .accordion-button {
  //     padding: 10px;
  //   }

  //   .accordion-button:focus {
  //     box-shadow: none;
  //   }
  // }
}
.clear-button {
  margin-top: 30px;
}

.search-bar {
  // margin-top: 30px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px 8px;
  .search-input {
    display: flex;
  }
}

.form-control {
  border: unset;
  font-size: 16px;
  color: var(--hfont);
  font-family: inherit !important;
  text-align: left;

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }

  &::placeholder {
    font-family: inherit !important;
    color: #cccccc;
    font-size: 14px;
    @media only screen and (min-width: 1600px) {
      font-size: 16px;
    }
  }

  &:focus {
    box-shadow: unset;
  }
}
