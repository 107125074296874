.date-range-filter {
  width: calc(100% - 52px);
  padding: 0;

  input {
    border-radius: 0 !important;
    outline: 0 !important;
    border: none !important;
    visibility: visible;
    display: inline-grid;
    grid-template-columns: 0 min-content;
    margin: 0 !important;
    padding: 8px 8px !important;
    box-sizing: border-box;
  }

  .first-date-picker {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    overflow: hidden;

    input {
      border-right: 2px solid !important;
      border-right-color: var(--border1) !important;
    }
  }

  .first-date-picker,
  .second-date-picker {
    font-size: 15px;
    width: inherit;
  }

  .compare-from-select {
    height: 38px;
    display: flex;
    width: 50px;
    align-items: center;
    border-radius: 5px !important;

    .react-select__control {
      border-radius: 0;
      height: 38px !important;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      padding: 0px 4px !important;

      .react-select__value-container {
        visibility: hidden;
        padding: 0;
        width: 1px;
      }

      .react-select__indicator {
        margin-bottom: 8px !important;
      }
    }

    .react-select__menu {
      width: 300px;
      @media (max-width: 768px) {
        width: 250px;
        transform: translate(-83%);
      }
    }
  }
}
