.btn-primary {
  background-color: transparent;
  border: 1px solid var(--mcolor-dark);
  color: var(--mcolor-dark);
  box-shadow: none;
  &:visited,
  &:focus {
    background-color: transparent;
  }
}

.no-outline {
  background-color: var(--mcolor) !important;
  border-color: var(--mcolor) !important;
  color: white;
  font-size: 14px;
}
.btn-primary:hover {
  background-color: var(--mcolor) !important;
  border-color: var(--mcolor) !important;
  .arrow {
    fill: #ffff !important;
  }
}

.btn:disabled {
  border-color: rgb(103, 84, 226) !important ;
  background-color: rgb(103, 84, 226) !important;
  .arrow {
    fill: #ffff !important;
  }
}
.btn-primary:disabled {
  box-shadow: 0 4px 9px -4px rgb(103, 84, 226) !important;
}
