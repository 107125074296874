.list-of-reports {
  color: var(--hfont);
}
.report-card {
  background-color: white;
  border-radius: 15px;
  padding: 12px;
  margin: inherit;
}
.mini-card {
  background-color: var(--card-color);
  color: white;
  border-radius: 22px;
  padding: 12px 18px;
  display: flex;
  align-items: flex-start;
  h4 {
    color: white;
    font-size: 26px;
  }
  .white-arrow-center {
    align-self: center;
  }
  .white-arrow-right {
    display: flex;
  }
  img {
    height: 24px;
    width: 24px;
  }
  .star-fill,
  .card-content,
  .white-arrow {
    margin: 12px 3px;
  }
}
.custom-reports {
  .MuiDataGrid-columnHeaderTitleContainer,
  .MuiDataGrid-cell {
    justify-content: center !important;
  }
}

.overflow-elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ele-hover:hover {
  background-color: lightgray;
}

//accordian
.accordion-button {
  padding: 8px 11px !important;
}
