.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  input {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--border1);
    margin-top: 4px;
    outline: none;
    color: var(--hfont) !important;
    font-family: "Univers Condensed";
  }
  input:disabled {
    background-color: #f2f2f2 !important;
  }
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: white;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: white;
  filter: drop-shadow(0px -1px 0px rgba(0, 0, 0, 0.3));
}

.react-datepicker {
  font-family: "Univers Condensed";
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.5rem;
  box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07),
    0 10px 20px -2px rgba(0, 0, 0, 0.04);

  .react-datepicker__month-container {
    padding: 12px;

    .react-datepicker__header {
      background: transparent;
      border-bottom: 1px solid whitesmoke;

      .react-datepicker__current-month {
        display: none;
      }

      .react-datepicker__month-select,
      .react-datepicker__year-select {
        width: 100%;
        padding: 5px;
        margin: 4px 0px;
        border-radius: 5px;
        border: 1px solid;
        border-color: var(--border1);
        outline: none;
        color: var(--mcolor) !important;
        font-size: 14px;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        margin-bottom: 5px;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 1.8rem;
        line-height: 1.8rem;
      }
    }

    .react-datepicker__month {
      .react-datepicker__day:hover {
        // background: #c8c3e2;
        background: rgb(103, 84, 227, 0.3);
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--selected:hover {
        background: #6754e2;
      }

      .react-datepicker__day--keyboard-selected {
        background: transparent;
      }

      .react-datepicker__day--keyboard-selected:hover {
        background: #6754e2;
        color: #fff;
      }

      .react-datepicker__day--disabled:hover {
        background: transparent;
      }
    }
  }
}
