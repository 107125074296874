.expander-text {
  color: var(--mdb-link-color);
  cursor: pointer;
}

.permissions {
  width: 100%;
  max-height: 200px;
  overflow: auto;
  .row {
    width: inherit;
    padding: 10px;
  }
  border-bottom: solid 1px rgba(224, 224, 224, 1);
}
