.report-permissions-modal {
  padding: 16px;

  h4 {
    margin-bottom: 16px;
  }

  .card {
    margin: 32px 0;
    padding: 16px;

    .w-fit {
      width: fit-content;
    }

    .MuiFormControlLabel-label {
      font-size: 20px;
    }

    .MuiFormControlLabel-root {
      padding: 8px 0;
    }

    .select-all-checkbox {
      border-bottom: solid 1px rgba(224, 224, 224, 1);
    }

    .permissions-form {
      border-bottom: none;
      width: 100%;
      .row {
        width: inherit;
        padding: 0;
      }
    }
  }
}
