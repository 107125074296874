.user-account-modal {
  padding: 16px;

  h4 {
    margin-bottom: 24px;
  }

  .input-field {
    margin-bottom: 19px;

    .user-label {
      margin-top: 0;
      .error {
        margin-left: 6px;
      }
    }

    .react-select__control {
      margin-top: 4px;
    }

    input {
      width: 100%;
      padding: 12px;
      border-radius: 5px;
      border: 1px solid;
      border-color: var(--border1);
      margin-top: 4px;
      outline: none;
      color: var(--hfont) !important;
    }
  }
}
