@font-face {
  font-family: "Univers Condensed";
  src: url("./assets/fonts/UNIVERSCONDENSED.TTF");
  font-weight: bold;
}
@font-face {
  font-family: "Univers Condensed Medium";
  src: url("./assets/fonts/universcondensed_medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Univers Condensed Regular Type";
  src: url("./assets/fonts/Univers_Light_Condensed_Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Univers EW";
  src: url(./assets/fonts/Univers_EW_Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Univers Extended";
  src: url(./assets/fonts/Univers_Extended_Medium.ttf);
}

@font-face {
  font-family: "Univers_Ext";
  src: url(./assets/fonts/Univers-BoldExt.ttf);
}

:root {
  --mcolor: #6754e2;
  --scolor: #00c99c;
  --mcolor-dark: #4433ae;
  --mcolor-light: rgb(103, 84, 227, 0.3);
  --hfont: #263b5e;

  --bfont: #6a7695;
  --hover: rgba(103, 84, 226, 0.2);

  --border1: rgba(68, 51, 174, 0.1);
  --border2: rgba(68, 51, 174, 0.2);
}

body {
  margin: 0;
  background-color: #f2faff !important;
  color: var(--bfont);
  font-family: "Univers Condensed" !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.spinner-border {
  color: var(--mcolor) !important;
}
.main-content {
  position: relative;
  min-height: 100vh;
  display: flex;

  &:before {
    content: "";
    position: fixed;
    background-image: url(../src/assets/svg/banner-shape.svg);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .banner-shape {
    position: fixed;
    top: 0;
    z-index: -1;

    &.banner-shape-top {
      left: 50%;
      transform: translateX(-50%);
    }

    &.banner-shape-corner {
      right: 0;
    }

    &.shape-dot {
      top: 50%;
      left: 50%;
    }

    &.shape-triangel {
      right: 5%;
      top: 25%;
    }

    &.shape-dot-yellow {
      left: 14%;
      top: 23%;
    }
  }
}
.rdrDateRangePickerWrapper {
  .rdrStaticRanges,
  .rdrInputRanges {
    font-size: 16px;
  }
}
.rdrDateRangeWrapper {
  width: 100%;

  .rdrDateDisplayWrapper {
    .rdrDateDisplay {
      display: block;
      font-size: 16px;
      .rdrDateDisplayItem {
        height: 40px;
        display: block;
        width: 100%;
        line-height: 40px;
        margin: 5px;
        font-size: 16px;
      }
    }
  }
  .rdrMonths {
    display: block;
    font-size: 14px;
    align-self: center;
  }
}

.MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

.bg-lightGreen {
  background-color: #e6ffe6;
}
