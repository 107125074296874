.auth-wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

h4 {
  font-size: 28px;
  font-weight: bold;
  color: var(--hfont);
}
h5,
h6 {
  color: var(--hfont);
}
.label {
  font-size: 18px;
  font-weight: bold;
  color: var(--hfont);
}

.card-layout {
  margin: 12px 0 0 0;
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  border: none;
}
.user-label {
  margin-top: 10px;
}

.action-button {
  border: none;
  background-color: var(--mcolor);
  border-radius: 10px;
  padding: 10px 16px 10px 16px;
  display: flex;
  color: white;
  margin: 0 10px 24px 0;
  cursor: pointer;
  .action-text {
    margin-left: 5px;
  }
}

.action-button:disabled {
  background-color: #9183ec !important;
  border-color: #9183ec !important;
  cursor: not-allowed;
}

.user-card-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
  div {
    width: 10rem;
  }
}
.action-button-outline {
  background-color: transparent;
  border: 1px solid;
  border-color: var(--mcolor);
  border-radius: 10px;
  padding: 10px 16px 10px 16px;
  display: flex;
  color: var(--mcolor);
  margin: 0 10px 24px 0;
  cursor: pointer;
  .action-text {
    margin-left: 5px;
  }
}
.card {
  border: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  .card-title {
    color: var(--hfont);
  }
}

.add-class-select {
  .react-select__indicators {
    align-self: center;
  }
  .react-select__control {
    min-height: 50px;
    border: 1px solid !important;
    border-color: var(--border1) !important;
  }
}
.react-select__menu {
  z-index: 10 !important;
}
hr {
  border: 1px solid !important;
  border-color: var(--border2) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.inputbox {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid;
  border-color: var(--border1);
  margin-top: 4px;
  outline: none;
  color: var(--hfont) !important;
}
.banner-img {
  .img-fluid {
    height: 500px;
  }
}

// recharts
.recharts-pie-label-line,
.recharts-line-dot,
.recharts-legend-wrapper,
.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  display: none !important;
}
.recharts-rectangle {
  :hover {
    background-color: none;
  }
}
.recharts-brush {
  stroke-opacity: 0.35;
}

.recharts-brush-texts {
  display: none;
}

// datagrid css
.MuiDataGrid-root {
  font-family: "Univers Condensed" !important;
}
.MuiDataGrid-pinnedColumnHeaders {
  background-color: #4433ae !important;
  padding: 0px !important;
}
.MuiDataGrid-withBorderColor {
  outline: none !important;
}
.cstm-mui-datagrid .MuiDataGrid-main > div:nth-child(3) {
  display: none;
}
.MuiDataGrid-columnHeaders {
  background-color: #6754e2 !important;
  border-radius: 10px 10px 0 0;
  color: white !important;
  font-size: 18px;
  .MuiDataGrid-columnHeader--moving {
    color: #6754e2 !important;
  }
  .MuiSvgIcon-root {
    fill: white !important;
  }
}

.MuiDataGrid-virtualScroller {
  background-color: white !important;
  font-size: 16px;
}

.MuiSvgIcon-root {
  color: white !important;
}
.MuiDataGrid-menu {
  background-color: white !important;
  border-radius: 4px !important;
  .MuiSvgIcon-root {
    color: #6754e2 !important;
  }
  .MuiTypography-root {
    font-family: "Univers Condensed" !important;
    font-size: 16px !important;
  }
}
.MuiDataGrid-columnHeaderTitleContainer {
  .MuiButtonBase-root {
    .MuiSvgIcon-root {
      fill: white !important;
    }
  }
}
.MuiCheckbox-root {
  .MuiSvgIcon-root {
    fill: var(--mcolor) !important;
  }
}
.MuiDataGrid-overlay {
  .MuiCircularProgress-svg {
    color: var(--mcolor) !important;
  }
}

.MuiDataGrid-filterForm {
  .MuiSvgIcon-root {
    color: #858c93 !important;
  }
}
.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel,
.MuiTablePagination-select,
.MuiTablePagination-menuItem {
  font-family: "Univers Condensed" !important;
  font-size: 16px !important;
  margin-bottom: 0;
}
.MuiTablePagination-select {
  margin-top: 4px !important;
}
.MuiSvgIcon-root {
  path {
    fill: var(--hfont);
  }
}
button:disabled,
button[disabled] {
  opacity: 0.5;
}
// error toast
.custom-error-toast {
  border-radius: 0.5rem;
  overflow: hidden;

  .toast-header,
  .toast-body {
    border-color: #f4c8cf;
    background-color: #f9e1e5;
    color: #af233a;
    // color: #dc4c64   // bootsrap danger;
  }

  .toast-header {
    img {
      margin-right: 7px;
    }
  }
}

//matrics card
.metrics-label {
  color: var(--hfont) !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

//modal
//modal css
.special-modal .modal-content {
  background-color: #eaf4fe !important;
}
.special-modal .modal-header {
  justify-content: center;
  margin: 0px;
}

.special-modal .modal-header h4 {
  margin-bottom: 0;
}

.MuiFormControlLabel-label {
  color: var(--bfont);
  font-family: "Univers Condensed" !important;
  font-size: 16px;
}
