.color-rectangle {
  height: 14px;
  width: 16px;
  border-radius: 2px;
  margin: "5px";
}

.border-radius-tr-br-0 .react-select__control {
  border-radius: 0;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-right: 2px solid var(--border1) !important;
}

.border-radius-tl-bl-0 .react-select__control {
  border-radius: 0;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
