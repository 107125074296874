.table-content {
  .table-header {
    background-color: var(--mcolor);
    height: 4rem;
    align-items: center;
    justify-content: center;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid;
    border-color: var(--border1);
  }
  .left-col-header {
    background-color: var(--mcolor);
    max-width: calc(100% - 90%);

    align-items: center;
    justify-content: center;
    color: white;
    border: 1px solid;
    border-color: var(--border1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left-col-header:last-child {
    border-radius: 10px !important;
  }
  .col-content {
    border: 1px solid;
    border-color: var(--border1);
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col-head {
    border: 1px solid;
    border-color: var(--border1);
    text-align: center;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .last-row {
    .last-col:last-child {
      border-bottom-right-radius: 15px;
    }
    .last-col:first-child {
      border-bottom-left-radius: 15px;
    }
  }
}
