.cstm-mui-datagrid {
  ::-webkit-scrollbar {
    width: 15px; /* Adjust the width as needed */
    background-color: transparent; /* Hide the scrollbar background */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ddd; /* Customize the color of the scrollbar thumb */
  }
}

.filter_label {
  color: #485166;
  font-size: 18px;
  font-weight: 500;
}
