.error {
  color: #f62e52;
}
.active-cell {
  color: #f62e52;
  background-color: rgba(246, 46, 82, 0.15);
  border-radius: 5px;
  padding: 4px 14px 4px 14px;
}
.inactive-cell {
  color: #009efb;
  background-color: rgba(0, 158, 251, 0.15);
  border-radius: 5px;
  padding: 4px 14px 4px 14px;
}
.action-svg {
  background-color: var(--hover);
  border-radius: 5px;
  padding: 4px 4px 4px 8px;
}
.action-delete-svg {
  background-color: rgba(246, 46, 82, 0.15);
  border-radius: 5px;
  padding: 4px 4px 4px 4px;
}

.add-user-card {
  color: var(--hfont) !important;
  .user-label {
    margin-top: 10px;
  }
  input {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--border1);
    margin-top: 4px;
    outline: none;
    color: var(--hfont) !important;
  }

  .user-card-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    div {
      width: 10rem;
    }
  }
}
.sub-menu {
  font-size: 18px;
  div {
    padding: 15px;
    margin: 5px;
    text-align: center;
    border-bottom: 3px solid;
    color: var(--hfont);
    border-color: var(--mcolor);
  }
}
.permission-card {
  color: var(--hfont) !important;
  background-color: white;
  padding: 18px;

  .user-label {
    margin-top: 16px;
  }
  input {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--border1);
    margin-top: 4px;
    outline: none;
    color: var(--hfont) !important;
  }
}
