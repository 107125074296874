.user-accounts {
  &-filter {
    .search-input {
      img {
        display: none;
      }
    }
    button {
      height: 34px;
    }
  }
}
