.custom-error {
  border-radius: 0.5rem;
  overflow: hidden;
  border-color: #f4c8cf;
  background-color: #f9e1e5;
  color: #af233a;
  padding: 5px 10px;
  width: fit-content;
  margin: 5px 0;
}
