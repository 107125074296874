.form-switch .form-check-input:after {
  background-color: white !important;
  margin-top: inherit !important;
  margin-left: 0.4rem !important;
  box-shadow: none !important;
  transition: background-color 0.5s, transform 0.5s;
}
.form-switch {
  align-self: flex-start !important;
}

.form-switch .form-check-input {
  border-radius: 1rem !important;
  width: 3.2rem !important;
  height: 1.875rem !important;
  background-color: rgb(179, 190, 206) !important;
  margin-top: 0.3em !important;
  margin-right: 8px !important;
}

.form-switch .form-check-input:checked[type='checkbox']:after {
  background-color: white !important;
  margin-top: inherit !important;
  margin-left: 1.6rem !important;
  box-shadow: none !important;
}
.form-switch .form-check-input:checked[type='checkbox'] {
  background-color: var(--mcolor) !important;
  transition: background-color 0.5s, transform 0.5s !important;
}
.form-switch .form-check-input:focus:before {
  box-shadow: none !important;
}
