.footer-container {
  // margin-top: 2rem;
  height: 60px;
  padding: 1rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    justify-content: center;
    flex-wrap: wrap;
  }
}
