.job-details-card {
  height: calc(100vh - 234px);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .h-full {
    height: 100%;
  }

  .card {
    border: 1px solid;
    border-color: var(--border1);
  }

  .company-profile {
    font-size: 18px !important;
    color: var(--hfont);
  }

  .company-logo {
    display: flex;
    align-items: center;
    margin: auto;
    height: 150px;
    width: 150px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    img {
      width: 100%;
    }
  }

  .job_subsection-title {
    color: var(--hfont);
    font-size: 20px;
    font-weight: bold;
  }

  .job_subsection-body {
    color: #727fa2;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

  .job_candidate-targets {
    color: var(--mcolor);
    font-size: 48px;
    font-weight: bold;
    margin: 0;
  }

  .badge {
    color: white;
    background-color: var(--mcolor);
    padding: 10px 18px;
    font-size: 15px;
    width: 100%;
  }

  .job_subsection-subtitle {
    color: #56627c !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    font-family: "Univers Condensed Medium" !important;
  }

  .job_name {
    width: calc(100% - 174px);
  }

  .job_description {
    color: #56627c;
    a,
    code {
      color: #56627c;
    }
    a {
      text-decoration: underline;
    }
    table {
      width: 100%;
      margin-bottom: 18px;
      th {
        width: 120px;
        text-align: center;
      }
    }
    table,
    td,
    th {
      font-size: 16px;
      border: 1px solid;
      padding: 8px;
    }
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 20px;
    }
    iframe {
      width: 100%;
      height: 350px;
    }
  }

  .spinner-border {
    color: var(--mcolor);
  }

  .cstm-mui-datagrid {
    height: 100%;
    width: 100%;
  }
}
