.navbar {
  padding-left: 2em;
  .logo-link {
    img {
      max-width: 100px;
      @media only screen and (min-width: 1600px) {
        max-width: 180px;
      }
    }
  }
  .nav-link {
    letter-spacing: 0.02rem;
    color: var(--bfont);
    font-size: 16px;
    font-weight: bold;
    &.active {
      color: var(--hfont);
    }

    &:hover {
      color: var(--mcolor-dark);
    }
  }
  .profile-image-container {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid var(--mcolor-dark);
    display: flex;
    align-items: center;
    justify-content: center;

    .profile-image-placeholder {
      color: var(--mcolor);
      font-size: 14px;
    }
  }

  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .language-button {
    background-color: white;
    border: 1px solid #c8d1d9;
    box-shadow: 0 5px 20px 0 rgba(38, 59, 94, 0.2);
    border-radius: 40px;
    padding: 0px 20px 0px 0px;
    display: flex;
    align-items: center;
    align-self: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 45px;
    width: 110px;

    justify-content: center;
    flex-wrap: nowrap;
  }

  // .language-button:hover {
  //   background-color: var(--hover);
  // }
  .language-button.clicked {
    transform: translateY(2px);
  }
  .language-flag {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 3px;
  }
  .language-toggel-switch {
    input[type="checkbox"] {
      display: none;
    }

    #switch-button {
      position: relative;
      display: block;
      width: 58px;
      height: 30px;
      background-color: #fff;
      border-radius: 100vmax;
      cursor: pointer;

      border: 1px solid var(--mcolor);
      overflow: hidden;
    }

    #switch-button-toogel {
      width: 100%;
      height: 100%;
      background-image: url(../../assets/svg/lang_switch_bg.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      position: relative;
      top: 2px;
      left: -29px;
      border-radius: 290px;
      transition: 0.4s ease left, 0.4s ease background-position;
      z-index: 2;
    }

    #eng_lan,
    #jp_lan {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: var(--mcolor);
      font-size: 16px;
      font-weight: bold;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

      z-index: 1;
      margin-left: 5px;
    }

    #jp_lan {
      margin-left: 33px;
    }

    #lol-checkbox:checked + #switch-button #switch-button-toogel {
      left: 29px;
    }
  }
  .dropdown-menu {
    .active {
      background-color: transparent;
    }
  }
  .notify-class {
    align-self: center;
    .notify-text {
      color: var(--mcolor);
    }
    .notification-badge {
      font-weight: bold !important;
      font-size: 12px;
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-item {
      border-bottom: 1px solid;

      display: flex;
      text-wrap: wrap;
      border-color: var(--border1);
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
  .header-profile {
    .profile-text {
      font-size: 14px !important;
    }
  }
}

.logo-image-icon {
  max-width: 40px;
  max-height: 40px;
}
