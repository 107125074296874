.react-select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.react-select__placeholder {
  color: var(--hfont) !important;
  font-size: 15px;
}
.react-select__control {
  width: 100%;
  // min-width: 10rem;
  border: unset !important;

  &.react-select__control--is-focused {
    box-shadow: unset !important;
  }
}

.react-select__input-container {
  .react-select__input {
    color: var(--hfont) !important;
  }
}

.react-select__value-container {
  font-size: 15px;
  font-weight: 500;
  @media only screen and (min-width: 1600px) {
    font-size: 16px;
  }
  .react-select__multi-value {
    background-color: #cbd5ff !important;
    .react-select__multi-value__remove:hover {
      background-color: var(--hover) !important;
      .css-tj5bde-Svg {
        fill: #6754e2;
      }
    }
  }
}

.react-select__indicator-separator {
  display: none;
}

.react-select__menu {
  .react-select__option {
    cursor: pointer;
    &.react-select__option--is-selected {
      background-color: var(--mcolor);
    }

    &:not(.react-select__option--is-selected) {
      &.react-select__option--is-focused {
        background-color: var(--mcolor-light);
      }
    }
  }
}
.css-tlfecz-indicatorContainer {
  color: var(--hfont) !important;
}
.react-select__indicators {
  color: var(--hfont) !important;
  // display: flex !important;
  // flex-direction: column !important;
  // .react-select__clear-indicator {
  // display: none;
  // }
}
