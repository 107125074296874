.app-container {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  overflow: hidden;
  .sidebar {
    @media only screen and (min-width: 1400px) {
      position: sticky !important;
      top: 0px;
      height: calc(100vh - 156px);
    }
  }
  .main-content {
    // min-height: calc(100vh - 60px);
    height: calc(100vh - 60px);
    overflow: scroll !important;
  }
}
