.select-label {
  width: 230px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  color: var(--hfont);
}
.label-viewer {
  background-color: white;
  display: grid;
  border-radius: 4px;
  color: var(--hfont) !important;
  font-size: 15px;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  height: 38px;
  justify-content: center;
  align-items: center;
  width: 8rem;
}
.active-pipeline-count {
  margin-top: 4.5rem;
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
}
/* styles.css */

.card-with-background {
  background-image: url("../../assets/svg/bubblebg.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #fff;
}
