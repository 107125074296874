.tooltip > div.tooltip-inner {
  background-color: #EAF3FE !important;
  color: var(--hfont) !important;
  font-weight: 500 !important;
  font-family: "Univers Condensed" !important;
}

.tooltip.show {
  opacity: 1 !important;
}
